<template>
  <div>
    <a-spin :spinning="spinning">
      <!-- <a-card :bordered="false"> -->
        <!-- <react-table
          ref="listTable"
          :table-data="tableData"
          :total-items="totalItems"
          :showTotal="showTotalFun"
          :actions-type="actionsType"
          :columns="columns"
          :scroll="{x: 1000}"
          :is-full="true"
          :current-page="pageNumber"
          @emitRefreshData="clearFilterAndRefresh"
          @emitSortData="updateSortData"
          @emitSelectChange="updateSelectChange"
          @emitOnPageChange="updatePageChange"
          @emitShowSizeChange="updateShowSizeChange"
        > -->
        <ele-table
          ref="rt"
          :columns="columns"
          :table-data="tableData"
          :total-items="totalItems"
          :is-full="true"
          :actionsType="actionsType"
          :current-page="pageNumber"
          :hide-row-selection="false"
          @emitRefreshData="clearFilterAndRefresh"
          @emitSelectChange="updateSelectChange"
          @emitOnPageChange="updatePageChange"
          @emitShowSizeChange="updateShowSizeChange"
        >
          <a-row
            :gutter="8"
            align="middle"
          >
            <!-- <a-col class="gutter-row" :span="2" style="text-align: right;">公司名称：</a-col> -->
            <a-col :span="6">
              <a-input
                v-model.trim="companyName" allowClear
                :placeholder="l('请输入公司名称')"
              />
            </a-col>
            <!-- <a-col class="gutter-row" :span="2" style="text-align: right;">风险评级：</a-col> -->
            <a-col class="gutter-row" :span="6">
              <a-select
                style="width: 100%;"
                @change="selectChange"
                placeholder="请选择风险评级"
                v-model="scoreTag"
                allowClear
              >
                <a-select-option :value="it.value" v-for="(it, i) in selectList" :key="i">
                  {{it.label}}
                </a-select-option>
              </a-select>
            </a-col>

            <a-col :span="10">
              <a-button type="primary" @click="search" v-if="isGranted('Pages.Questionnaire.Query')">
                {{ l("Search") }}
              </a-button>
              <!-- <a-button >{{ l("Reset") }}</a-button> -->
              <a-button
                @click="exportToExcel()" v-if="isGranted('Pages.Questionnaire.ExportExcel')"
              >
              <a-icon type="file-excel" />
                <span>{{ l("Export") }}</span>
              </a-button>
            </a-col>
          </a-row>
        </ele-table>
      <!-- </a-card> -->
    </a-spin>
  </div>
</template>


<script>
import { AppComponentBase } from "@/shared/component-base";
import { ModalHelper } from "@/shared/helpers";
import { CommonServiceProxy } from "@/shared/common-service";
import EleTable from "@/components/ele-table";
import QDtail from "./qDetail/qDetail";
import { fileDownloadService } from "@/shared/utils";


export default {
  mixins: [AppComponentBase],
  data() {
    return {
      spinning: false,
      sealService: null,
      commonService: null,
      selectedRowKeys: [],
      name: "",
      // 总数
      totalItems: 0,
      // 当前页码
      pageNumber: 1,
      // 共多少页
      totalPages: 1,
      // 条数显示范围
      pagerange: [1, 1],
      // 显示条数
      pageSizeOptions: ["10", "20", "30", "40"],
      request: { sorting: "", maxResultCount: 50, skipCount: 0 },
      // 用户表格
      columns: [
        {
          title: this.l("公司名称"),
          dataIndex: "companyName",
          sorter: true,
          align: "center",
          scopedSlots: { customRender: "companyName" },
        },
        {
          title: this.l("创始人/高层管理人员"),
          dataIndex: "manageName",
          sorter: true,
          align: "center",
          scopedSlots: { customRender: "manageName" },
        },
        {
          title: this.l("联系方式"),
          dataIndex: "phone",
          sorter: true,
          align: "center",
          scopedSlots: { customRender: "phone" },
        },
        {
          title: this.l("地址"),
          dataIndex: "address",
          sorter: true,
          align: "center",
          scopedSlots: { customRender: "address" },
        },
      
        
        {
          title: this.l("Actions"),
          dataIndex: "actions",
          sorter: true,
          align: "center",
          fixed: "right",
          scopedSlots: { customRender: "actions" },
        },
      ],
      // 用户数据
      tableData: [],
      actionsType: {},
      selectList: [
        {label: '高合规度', value: '高合规度'},
        {label: '中高合规度', value: '中高合规度'},
        {label: '中低合规度', value: '中低合规度'},
        {label: '低合规度', value: '低合规度'},
      ],
      filterText: "",
      scoreTag: undefined,
      companyName: "",

    }
  },
  created() {
    this.commonService = new CommonServiceProxy(this.$apiUrl, this.$api)
    this.initActionsType()
    this.getData();
  },
  methods: {
    selectChange() {

    },
    search() {
      this.pageNumber = 1
      this.request.skipCount = 0
      this.getData()
    },
    async getData() {
      this.spinning = true;
      this.$nextTick(() => {
        this.$refs.rt.clearData();
      })
      try {
        let res = await this.commonService.get({
          url: '/api/services/app/Questionnaire/GetPaged',
          params: {
            companyName: this.companyName,
            scoreTag: this.scoreTag,
            filterText: this.filterText,
            sorting: this.request.sorting,
            maxResultCount: this.request.maxResultCount,
            skipCount: this.request.skipCount,
          }
        })
        this.spinning = false;
        this.tableData = res.items.map(item => {
          return{
            ...item,
            questionAnswer: JSON.parse(item.questionAnswer),
            address: `${item.city.split('-').join('')}${item.address}`,
          }
        })
        // console.log(this.tableData)
        this.totalItems = res.totalCount;
        this.totalPages = Math.ceil(res.totalCount / this.request.maxResultCount);
        this.pagerange = [
          (this.pageNumber - 1) * this.request.maxResultCount + 1,
          this.pageNumber * this.request.maxResultCount,
        ];
      } catch (error) {
        console.log(error)
        this.spinning = false;
      }
    },
    async _getTrainTestQuestionNoAnswer(opts) {
      this.spinning = true
      try {
        let res = await this.commonService.get({
          url: '/api/services/app/Train/GetTrainTestQuestion',
          params: { trainSceneGuid: opts.trainSceneGuid }
        })
        console.log(opts)
        let questionAnswer = opts.questionAnswer
        const arr = res.map(item => {
          let k = item.guid
          let userAns = questionAnswer[k]
          return {
            ...item,
            userAnswer: userAns
          }
        })
        this.createReactModify(arr,opts)
     
        this.spinning = false
      } catch (error) {
        this.spinning = false
      }

    },
     // 清除条件并刷新
    clearFilterAndRefresh() {
      this.request = { sorting: "", maxResultCount: 50, skipCount: 0 };
      this.name = "";
      this.filterText = "";
      this.getData();
    },
    // 分页事件
    showTotalFun() {
      return this.l(
        "GridFooterDisplayText",
        this.pageNumber,
        this.totalPages,
        this.totalItems,
        this.pagerange[0],
        this.pagerange[1]
      );
    },
    // 排序更新data
    updateSortData(newV) {
      let { columnKey, order } = newV;
      this.request.sorting = order ? `${columnKey} ${order}` : "";
      this.getData();
    },
    // 分页
    updatePageChange(newV) {
      let { page, pageSize } = newV;
      this.pageNumber = page;
      this.request.skipCount = (page - 1) * this.request.maxResultCount;
      this.getData();
    },
    updateShowSizeChange(newV) {
      let { current, size } = newV;
      this.pageNumber = 1;
      this.request.maxResultCount = size;
      this.getData();
    },
    // table选择事件
    updateSelectChange(newV) {
      let { selectedRowKeys, selectedRows } = newV;
      this.selectedRowKeys = selectedRowKeys;
    },

    // 初始化需要显示的func btn
    initActionsType() {
      let _this = this
      let obj = {
        type: "seal",
        isShow: true,
        fns: {
          dropList: [
            { granted: true, name: this.l("查看问卷"), icon: "edit", fn: (data) => {
              _this._getTrainTestQuestionNoAnswer(data)
            } },
          ],
        },
      };
      this.actionsType = obj;
    },
    async exportToExcel() {
      this.spinning = true
      try {
        let res = await this.commonService.get({
          url: '/api/services/app/Questionnaire/GetToExcelFile',
          params: {}
        })
        console.log(res)
        fileDownloadService.downloadTempFile(res)
        this.spinning = false
      } catch (error) {
        console.log(error)
        this.spinning = false
      }
    },
    async createReactModify(arr,opt, type) {
      let list = []
      let reqParams = []
      // if (item && item.id) {
      //   // console.log(item)
      //   reqParams.push({key: 'id', value: item.id})
      // }
      let otherPrams = {}
      ModalHelper.create(
        QDtail,
        {
          params: {
            type: type, // edit,preview,create
            data: arr,
            opt:opt,
            reqParams: [
              ...reqParams,
            ],
            reqUrl: {
              getById: '',
              save: '',
            },
            otherParams: {},
            getItemfn: async (res, form, options) => {
              if (options) {
                // ...
              }
            },
            submitfn: async (values, queryParams) => {
            }
          },
        },
        { 
          isChange: true,
          width: "800px",
        }
      ).subscribe((res) => {
        if (res) {
          this.clearFilterAndRefresh();
        }
      });
    },
  },
  components: { EleTable },

}
</script>

<style lang="less" scoped>

</style>>