<template>
  <section>
    <div class="modal-header">
      <div class="modal-title">
        {{ l("查看") }}
      </div>
    </div>

    <ul class="list-w" v-for="(item, index) in list" :key="index">
      <li>
        <div class="title">{{ index + 1 }}.{{ item.stem }}</div>
        <div class="ans-w">
          <div
            v-for="(it, i) in item.trainTestQuestionOptions"
            :key="i"
            class="iw"
          >
            <span
              v-if="false"
              :class="
                item.answer === it.optionKey
                  ? 'right'
                  : item.userAnswer !== item.answer
                  ? 'wrong'
                  : ''
              "
              >{{ it.optionKey }}:{{ it.optionValue }}</span
            >

            <span
              :class="
                item.userAnswer.indexOf(it.optionKey) > -1 ? 'right' : ' '
              "
              >{{ it.optionKey }}:{{
                it.optionValue.replace("$$", ":" + remark[item.guid])
              }}</span
            >
          </div>
        </div>
      </li>
    </ul>
  </section>
</template>

<script>
import { AppComponentBase } from "@/shared/component-base";
import { ModalComponentBase } from "@/shared/component-base";
import { CommonServiceProxy } from "@/shared/common-service";

export default {
  name: "react-modify",
  mixins: [AppComponentBase, ModalComponentBase],
  data() {
    return {
      spinning: false,
      commonService: null,
      form: this.$form.createForm(this, { name: "rm" }),
      defaultRules: [
        { required: true, message: this.l("ThisFieldIsRequired") },
      ],
      list: [],
      opt: {},
      remark: {},
      otherParams: {},
    };
  },
  created() {
    this.fullData();
    // this.commonService = new CommonServiceProxy(this.$apiUrl, this.$api);
    // console.log(this.params.data)
  },
  mounted() {
    this.$nextTick(async () => {
      this.list = this.params.data;
      this.opt = this.params.opt;
      if (this.opt.remark != null) {
        this.remark = JSON.parse(this.opt.remark);
      }
      console.log(this.list, this.opt, this.remark);
    });
  },
  methods: {},
};
</script>


<style lang="less" scoped>
@import "../../../../styles/common.less";

.modal-header {
  .anticon-share-alt {
    margin-right: 10px;
  }
}
.list-w {
  li {
    list-style: none;
    .title {
      padding: 4px 0;
      .status {
      }
      .right {
        color: @color-green;
      }
      .wrong {
        color: red;
      }
    }
    .ans-w {
      .iw {
        line-height: 36px;
        span {
          padding: 2px 10px;
          border: 1px solid @color-gray;
          border-radius: 4px;
        }
        .right {
          color: @color-white;
          background-color: @color-green;
          border: 1px solid @color-green;
        }
        .wrong {
          color: red;
          border: 1px solid red;
        }
        .common {
        }
      }
    }
  }
}

.modal-footer {
  border: none;
}
.form-wrapper {
  .ant-form-item {
    margin-bottom: 14px;
  }
  .preview-list {
    margin-bottom: 0;
    padding: 0;
    li {
      list-style: none;
      line-height: 30px;
    }
  }
}
</style>